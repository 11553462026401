import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AssessmentIcon from '@material-ui/icons/Assessment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PaymentIcon from '@material-ui/icons/MonetizationOn';
import PageviewIcon from '@material-ui/icons/Pageview';
import logo from 'logo_white_yellow.svg';

import './SideBar.css';

const SideBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem('redirectUri', `${location.pathname}${location.search}`);
  }, [location]);
  const isProd = process.env.REACT_APP_NODE_ENV === 'production';

  // extract pathname from location
  const { pathname } = useLocation();

  return (
    <div>
      <div className="brand-area">
        <img
          alt="Optty-Logo"
          className="brand-logo brand-sub-logo"
          src={logo}
        />
      </div>

      <div className="nav-links">
        <NavLink
          activeClassName={`menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`}
          className="menu-link"
          isActive={() => ['/app/onboarding'].some((x) => pathname.includes(x) || pathname === '/')}
          to="/app/onboarding/child"
        >
          <AccessibilityIcon className="link-icon" />{' '}
          <span>{t('Onboarding')}</span>
        </NavLink>
        <NavLink
          activeClassName={`menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`}
          className="menu-link"
          isActive={() => ['/app/invoicing'].some((x) => pathname.includes(x))}
          to="/app/invoicing/details"
        >
          <ReceiptIcon className="link-icon" />{' '}
          <span>{t('Invoicing')}</span>
        </NavLink>
        <NavLink
          activeClassName={`menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`}
          className="menu-link"
          isActive={() => ['/app/paymentMethods'].some((x) => pathname.includes(x))}
          to="/app/paymentMethods"
        >
          <PaymentIcon className="link-icon" />{' '}
          <span>{t('Payment Methods')}</span>
        </NavLink>
        <NavLink
          activeClassName={`menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`}
          className="menu-link"
          isActive={() => ['/app/auditLog'].some((x) => pathname.includes(x))}
          to="/app/auditLog"
        >
          <AssessmentIcon className="link-icon" />{' '}
          <span>{t('Audit Log')}</span>
        </NavLink>
        <NavLink
          activeClassName={`menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`}
          className="menu-link"
          isActive={() => ['/app/order-details'].some((x) => pathname.includes(x))}
          to="/app/order-details"
        >
          <PageviewIcon className="link-icon" />{' '}
          <span>{t('Order Details')}</span>
        </NavLink>
      </div>
    </div>
  );
};

export default SideBar;
