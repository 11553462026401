import axios, { AxiosResponse } from 'axios';
import { IIntegration, IPaymentBrand } from 'components/PaymentMethods/types';
import { IInvoiceEntity } from 'components/Invoicing/InvoiceDetails/InvoiceDisplay';
import { ICommissionLink } from 'components/Invoicing/CommissionLink/CommissionLinkDisplay';
import { IPartner } from 'components/Invoicing/CommissionLink/CommissionLinkUpdateCard';
import { IOnboardingOption } from 'routes/onboarding/OnboardingMerchant';
import { OnboardFamilyEntityDto } from 'components/Onboarding/types/onboardingDto/OnboardFamilyEntityDto';
import { OnboardingForm } from 'components/Onboarding/OnboardingFormClass';
import { UserForm, IUserFormResult } from 'components/Onboarding/OnboardingUserForm';
import { IInvoiceDetailsForm } from 'components/Invoicing/InvoiceDetails/UpdateCard/InvoiceDetailsUpdateCard';
import { IFilter } from 'components/Reports/TransactionReportPage';
import storageService from './storage.service';
import { IUser } from './auth.service';

const adminApi = axios.create({
  baseURL: process.env.REACT_APP_OPTTY_ADMIN_API_URL,
  headers: {
    Authorization: `Bearer ${storageService.getJwtCookie()}`,
    'Content-Type': 'application/json',
  },
});

export default {
  async generateInvoice(redirectUri: string, code: string, month: number, year: number) {
    return adminApi.put('/invoice', {
        redirectUri, code, month, year,
      })
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in generate invoice  ${err}`);
          console.error(err);
          throw new Error(err);
        });
  },

  async updatePaymentMethod(paymentMethod: IPaymentBrand): Promise<any> {
    const {
      id: _id,
      integrations: _integrations,
      imageUrl: _imageUrl,
      ...payload
    } = paymentMethod;

    console.log(payload);

    return adminApi.put(`/paymentMethods/brand/${paymentMethod.id}`, payload)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(JSON.stringify(err));
          console.error(`error thrown in post payment method api service  ${err}`);
          throw err;
        });
  },

  async removePaymentMethod(brandId: string): Promise<void> {
    console.log(`adminApi.delete('/paymentMethods/brand/${brandId}')`);
    return adminApi.delete(`/paymentMethods/brand/${brandId}`)
        .then(async (res: AxiosResponse<void>) => {
          if (res.status >= 200 && res.status < 300) {
            return;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in post payment method api service  ${err}`);
          throw err;
        });
  },

  async createPaymentMethodBrand(brand: IPaymentBrand): Promise<IPaymentBrand> {
    const {
      id: _id,
      integrations: _integrations,
      imageUrl: _imageUrl,
      ...payload
    } = brand;

    return adminApi.post('/paymentMethods/brand', payload)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in post payment method api service  ${err}`);
          throw err;
        });
  },

  async onboardFamilyEntity(onboardingForm: OnboardingForm, hasParent: boolean): Promise<any> {
    console.log(onboardingForm);
    const parsed = new OnboardFamilyEntityDto(onboardingForm, hasParent);
    console.log('parsed ----- body sent to onboarding');
    console.log(parsed);

    return adminApi.post('/family', parsed)
        .then(async (res: AxiosResponse<any>) => {
          console.log('in then');
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            // throw result as an error
            console.log(res);
            throw res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err?.data);
          console.log(err?.Response?.data);
          console.error(`error thrown in onboardFamilyEntity api service  ${err}`);
          throw err;
        });
  },

  async onboardUser(onboardingForm: UserForm): Promise<IUserFormResult> {
    console.log('onboardingForm sent to onboard user');
    console.log(onboardingForm);

    return adminApi.post('/family/user', onboardingForm)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            // throw result as an error
            console.log(res);
            throw res.data;
        })
        .catch((err) => {
          console.log(err);
          console.error(`error thrown in onboard User api service  ${err}`);
          throw err;
        });
  },

  async getCommissions() {
    return adminApi.get('/commissions')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getInvoiceHistory api service  ${err}`);
          throw err;
        });
  },

  async getOrderDetails(merchantReference: string, merchantId: string) {
    return adminApi.get(`orders/${merchantReference}/details?merchantId=${merchantId}`)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status === 200) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in order details api service  ${err}`);
          throw err;
        });
  },

  async getTransReport(filter: IFilter) {
    return adminApi.post('reports/get-transaction-wise-report', filter)
        .then(async (res) => {
          console.log(`getTransReport 1==>: ${JSON.stringify(res)}`);
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in set paid commission  ${err}`);
          throw err;
        });
  },

  async setCommissionPaid(invoiceId: string) {
    return adminApi.post(`/commissions/paid/${invoiceId}`)
        .then(async (res) => {
          console.log(`res in onboardMerchant: ${JSON.stringify(res)}`);
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in set paid commission  ${err}`);
          throw err;
        });
  },

  async updateInvoiceDetails(
    form: IInvoiceDetailsForm,
    familyId: string,
  ) {
    console.log(form);
    return adminApi.put(
      `/invoiceDetails/${familyId}`,
      { ...form },
    ).then(async (res: AxiosResponse<any>) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
        throw new Error(res.statusText);
      })
      .catch((error) => {
        throw error.response.data;
      });
  },

  async createInvoiceDetails(
    form: IInvoiceDetailsForm,
    uppId: string,
  ) {
    console.log(form);
    const body = form;
    if (form.xeroContactId === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      delete body.xeroContactId;
    }
    return adminApi.post(
      `/invoiceDetails/${uppId}`,
      body,
    ).then(async (res: AxiosResponse<any>) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
        throw new Error(res.statusText);
      })
      .catch((error) => {
        throw error.response.data;
      });
  },

  async getInvoiceDetails(familyId: string): Promise<IInvoiceEntity> {
    return adminApi.get(`/invoiceDetails/${familyId}`)
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getInvoiceHistory api service  ${err}`);
          throw err;
        });
  },

  async getInvoicableFamilies(): Promise<IInvoiceEntity[]> {
    return adminApi.get('/family/invoicable')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            res.data.txnCost = res.data.txnPricing;
            return res.data.map((family: any) => {
              const map = family;
              map.txnCost = family.txnPricing;
              delete map.txnPricing;
              console.log(map);
              return map;
            });
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getInvoicableFamilies api service  ${err}`);
          throw err;
        });
  },

  async getInvoicableFamiliesMissing(): Promise<{
      name: string,
      grandparentId: string | null,
      parentId: string | null,
      childId: string | null
    }[]> {
    return adminApi.get('/family/invoicable/missing')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getInvoicableFamilies api service  ${err}`);
          throw err;
        });
  },

  async getCurrentCommissionLinks(): Promise<ICommissionLink[]> {
    return adminApi.get('/commissions/linkDetails')
      .then(async (res: AxiosResponse<any>) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error(res.statusText);
      })
      .catch((err) => {
        console.error(`error thrown in getInvoicableFamilies api service  ${err}`);
        throw err;
      });
  },

  async getCommissionLinksByFamily(familyId: string): Promise<ICommissionLink[]> {
    return adminApi.get(`/commissions/linkDetails/history/${familyId}`)
      .then(async (res: AxiosResponse<any>) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error(res.statusText);
      })
      .catch((err) => {
        console.error(`error thrown in getInvoicableFamilies api service  ${err}`);
        throw err;
      });
  },

  async updateCommissionLink(familyEntityId: string, commissionPartnerId: string, startYear: string, startMonth: string, percent: string) {
    console.log({
      familyEntityId, commissionPartnerId, percent: Number(percent), startDate: `${startYear}-${startMonth}-01`,
      });
    return adminApi.put('/commissions', {
      familyEntityId, commissionPartnerId, percent: Number(percent), startDate: `${startYear}-${startMonth}-01`,
      })
      .then(async (res: AxiosResponse<any>) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error(res.statusText);
      })
      .catch((err) => {
        console.error(`error thrown in put commissions api service  ${err}`);
        console.log(err);
        throw err;
      });
  },

  async getCommissionPartners(): Promise<{ partners: IPartner[] }> {
    return adminApi.get('/commissions/partners')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in get commisison partners api service  ${err}`);
          throw err;
        });
  },

  async getMerchants(): Promise<IOnboardingOption[]> {
    return adminApi.get('/family/child')
        .then(async (res: AxiosResponse<any>) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getMerchants api service  ${err}`);
          throw err;
        });
  },

  async getAuditLog(): Promise<any> {
    return adminApi.get('/auditLog')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getAuditLog api service  ${err}`);
          throw err;
        });
  },

  async getPaymentMethodBrands(): Promise<IPaymentBrand[]> {
    return adminApi.get('/paymentMethods/brand')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getPaymentMethodBrands api service  ${err}`);
          throw err;
        });
  },

  async getPaymentMethodIntegrations(): Promise<IIntegration[]> {
    return adminApi.get('/paymentMethods/integration')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getPaymentMethodBrands api service  ${err}`);
          throw err;
        });
  },

  async getParents(): Promise<any> {
    return adminApi.get('/family/parent')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getParents api service  ${err}`);
          throw err;
        });
  },

  async getGrandparents(): Promise<any> {
    return adminApi.get('/family/grandparent')
        .then(async (res) => {
          if (res.status >= 200 && res.status < 300) {
            return res.data;
          }
            throw new Error(res.statusText);
        })
        .catch((err) => {
          console.error(`error thrown in getGrandparents api service  ${err}`);
          throw err;
        });
  },

  // check authenticated
  async checkAuthenticated(jwt: string): Promise<boolean> {
    return adminApi.get('/auth', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error('Authentication failed');
      })
      .then((data: string) => {
        const isAuthenticated = Object.keys(data).length !== 0;
        return isAuthenticated;
      })
      .catch((error) => {
        console.error('Error occurred:', error);
        return false;
      });
  },

  // login
  async login(jwt: string): Promise<IUser | undefined> {
    console.log(`adminApi: ${adminApi}`);
    console.log(process.env.REACT_APP_OPTTY_ADMIN_API_URL);
    return adminApi.get('/auth/login', {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((res) => {
        console.log(`res in login: ${res}`);
        if (res.status >= 200 && res.status < 300) {
          return res.data;
        }
          throw new Error(`Authentication failed: ${res.statusText}`);
      })
      .catch((error) => {
        console.error('Error occurred unauthed:', error);
        return undefined;
      });
  },
};
